import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { Modal } from "@/component"
import { Head, Foot } from "@/component"
import { IMAGE } from "@/assets"
import { useTranslation } from "react-i18next"
import "./styles.less"

const IndexPage = ({ history }) => {
  const { t, i18n } = useTranslation()
  const [open, setOpen] = useState(false)
  
  return (
    <div className="home-page">
      <Head />
      <div className="session1">
        <div className="content-div">
          <h1 className="t1">{ t("session.1.t1") }</h1>
          <h2 className="t2">{ t("session.1.t2") }</h2>
          <button onClick={() => window.open("https://console.satogate.io/")}>{ t("get.started") }</button>
          <div className="list">
          {
            [ t("session.1.t3") ,  t("session.1.t4") ,  t("session.1.t5") ].map((item, index) => {
              return <div key={index} className="item">
                <img src={IMAGE[`session1_icon_${index + 1}`]} alt="" />
                <h4>{item}</h4>
              </div>
            })
          }
          </div>
        </div>
        <img src={IMAGE.session1_image_1} alt="" className="image" />
      </div>
      <div className="session3">
        <h1 className="t1">{ t("session.3.t1") }</h1>
        <h2 className="t2">{ t("session.3.t2") }</h2>
        <div className="list">
        {
          [
            {title: t("session.3.t3"), content: t("session.3.t4")},
            {title: t("session.3.t5"), content: t("session.3.t6")},
            {title: t("session.3.t7"), content: t("session.3.t8")},
            {title: t("session.3.t9"), content: t("session.3.t10")}
          ].map((item, index) => {
            return <div key={index} className="item">
              <img src={IMAGE[`session3_image_${index + 1}`]} alt="" />
              <h1>{item.title}</h1>
              <h4>{item.content}</h4>
            </div>
          })
        }
        </div>
      </div>
      <div className="session4">
        <img src={i18n.language.startsWith("zh") ? IMAGE.session4_image_4 : IMAGE.session4_image_3} alt="" className="image" />
        <div className="content-div">
          <h1 className="t1">{ t("session.4.t1") }</h1>
          <h2 className="t2">{ t("session.4.t2") }</h2>
          <div className="list">
          {
            [t("session.4.t3"), t("session.4.t4"), t("session.4.t5")].map((item, index) => {
              return <div key={index} className="item">
                <img src={IMAGE[`session4_icon_${index + 1}`]} alt="" />
                <h4>{item}</h4>
              </div>
            })
          }
          </div>
        </div>
      </div>
      <div className="session2">
        <h1 className="t1">{ t("session.2.t1") }</h1>
        <h2 className="t2">{ t("session.2.t2") }</h2>
        <div className="list">
        {
          [t("session.2.t3"), t("session.2.t4"), t("session.2.t5"), t("session.2.t6"), t("session.2.t7"), t("session.2.t8"), t("session.2.t9"), t("session.2.t10")].map((item, index) => {
            return <div key={index} className="item">
              <img src={IMAGE[`session2_image_${index + 1}`]} alt="" />
              <h1>{item}</h1>
            </div>
          })
        }
        </div>
      </div>
      <div className="session9">
        <h1 className="t1">{ t("session.9.t1") }</h1>
        <h2 className="t2">{ t("session.9.t2") }</h2>
        <div className="list">
        {
          new Array(49).fill('').map((item, index) => {
            return <img key={index} src={IMAGE[`session9_icon_${index + 1}`]} alt="" />
          })
        }
        </div>

      </div>
      {/* <div className="session7">
        <img src={IMAGE.session7_image_1} alt="" className="image" />
        <div className="content-div">
          <h1 className="t1">{ t("session.7.t1") }</h1>
          <h2 className="t2">{ t("session.7.t2") }</h2>
        </div>
      </div> */}
      <div className="session8">
        <h1 className="t1">{ t("session.8.t1") }</h1>
        <button onClick={() => window.open("https://console.satogate.io/")}>{ t("get.started") }</button>
      </div>
      <Modal open={open} closeModal={() => setOpen(false)} />
      <Foot />
    </div>
  )
}
export default withRouter(IndexPage)