import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import "@/i18n"
import { Root, Payment, NotFound } from "@/page"
import "./App.less"

const App = () => {

  return (
    <Router>
      <Switch>
        <Route exact path="/"><Root /></Route>
        <Route path="/payment"><Payment /></Route>
        <Route path="/*"><NotFound /></Route>
      </Switch>
    </Router>
  )
}

export default App