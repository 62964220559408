import React from "react"
import { withRouter } from "react-router-dom"
import "./styles.less"

const IndexPage = ({ history }) => {
  return (
    <div className="not-found-page">
      <h1>404</h1>
    </div>
  )
}
export default withRouter(IndexPage)