import axios from "axios"

const instance = axios.create({
  timeout: 30000,
  // baseURL: "https://api.futurespay.net"
  baseURL: "https://api.satogate.io"
})
// 请求拦截
instance.interceptors.request.use(
  config => {
    config.data = JSON.stringify(config.data)
    config.headers = {
      "Content-Type": "application/json"
    }
    return config
  }, 
  error => {
    return Promise.reject(error)
  }
)
// 响应拦截
instance.interceptors.response.use(
  res => {
    if (res.status === 200) {
      let response = res.data
      if (response.code === 0) {
        return response.data
      } else {
        return Promise.reject(response.message)
      }
    } else {
      return Promise.reject(res.statusText)
    }
  }, 
  error => {
    if (error.message.includes('timeout')) {
      return Promise.reject('网络异常，请求超时')
    } else {
      return Promise.reject(error)
    }
  }
)
// 请求方法
export const post = function post(url, params = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url,params).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
export const get = function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    instance.get(url, { params }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
