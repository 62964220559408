import React, { useState } from "react"
import { Modal, Input, message, Button } from "antd"
import { post } from "@/service"
import { IMAGE } from "@/assets"
import { useTranslation } from "react-i18next"
import "./styles.less"

const IndexComponent = (props) => {
  const { t } = useTranslation()
  const { open, closeModal } = props
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [legalEntityName, setLegalEntityName] = useState("")
  const [incorporation, setIncorporation] = useState("")
  const [email, setEmail] = useState("")
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()

  async function submit() {
    setActive(true)
    if (firstName.trim().length === 0 ||
        lastName.trim().length === 0 ||
        legalEntityName.trim().length === 0 ||
        incorporation.trim().length === 0 ||
        email.trim().length === 0) {
      return
    }
    try {
      setLoading(true)
      await post("/website/contact", {
        firstName: firstName,
        lastName: lastName,
        entityName: legalEntityName,
        country: incorporation,
        email: email
      })
      setLoading(false)
      messageApi.open({
        type: 'success',
        content: t("modal.t9")
      })
      closeModal()
    } catch (error) {
      setLoading(false)
      messageApi.open({
        type: 'error',
        content: JSON.stringify(error)
      })
    }
  }

  return (
    <div className="modal-component">
      { contextHolder }
      <Modal centered open={open} footer={null} destroyOnClose={true} closable={true} closeIcon={<img src={IMAGE.close} alt="" className="modal-close-button" />} wrapClassName="modal-class" onCancel={() => closeModal()} afterClose={() => {
        setFirstName("")
        setLastName("")
        setLegalEntityName("")
        setIncorporation("")
        setEmail("")
        setActive(false)
      }}>
        <div className="body-div">
          <h1 className="t1">{ t("modal.t1") }</h1>
          <h4 className="t2">{ t("modal.t2") }</h4>
          <h2 className="session-name">{ t("modal.t3") }</h2>
          <Input className="session-input" value={firstName} status={ firstName.trim().length === 0 && active ? "error" : "" } onChange={(event) => setFirstName(event.target.value)} />
          <h2 className="session-name">{ t("modal.t4") }</h2>
          <Input className="session-input" value={lastName} status={ lastName.trim().length === 0 && active ? "error" : "" } onChange={(event) => setLastName(event.target.value)} />
          <h2 className="session-name">{ t("modal.t5") }</h2>
          <Input className="session-input" value={legalEntityName} status={ legalEntityName.trim().length === 0 && active ? "error" : "" } onChange={(event) => setLegalEntityName(event.target.value)} />
          <h2 className="session-name">{ t("modal.t6") }</h2>
          <Input className="session-input" value={incorporation} status={ incorporation.trim().length === 0 && active ? "error" : "" } onChange={(event) => setIncorporation(event.target.value)} />
          <h2 className="session-name">{ t("modal.t7") }</h2>
          <Input className="session-input" value={email} status={ email.trim().length === 0 && active ? "error" : "" } onChange={(event) => setEmail(event.target.value)} />
          <Button className="button" loading={loading} onClick={() => submit()}>{ t("modal.t8") }</Button>
        </div>
      </Modal>
    </div>
  )
}

export default IndexComponent