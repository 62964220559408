import React, { useEffect, useState } from "react"
import { Modal } from "@/component"
import { IMAGE } from "@/assets"
import { useTranslation } from "react-i18next"
import "./styles.less"

const IndexComponent = () => {
  const { t } = useTranslation()
  const [offsetTop, setOffsetTop] = useState(0)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setOffsetTop(window.pageYOffset)
    }, true)
  }, [])

  return (
    <div className="head-component" style={{ backgroundColor: offsetTop === 0 ? "transparent" : "white" }}>
      <div className="body-div">
        <img src={IMAGE.headLogo} alt="" />
        <div className="buttons">
          {/* <button className="wallet-button" onClick={() => window.open("https://futures.cash/")}>{ t("personal.wallet") }<img src={IMAGE.pushIcon} alt="" /></button> */}
          <button className="start-button" onClick={() => window.open("https://console.satogate.io/")}>{ t("get.started") }</button>
        </div>
      </div>
      <Modal open={open} closeModal={() => setOpen(false)} />
    </div>
  )
}
export default IndexComponent
