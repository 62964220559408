export function getQueryVariable(variable) {
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i=0;i<vars.length;i++) {
		const element = vars[i]
		if(element.startsWith(`${variable}=`)){
			const elem = element.replace(`${variable}=`, '')
			return decodeURIComponent(elem)
		}
	}
	return(false);
}