import React from "react"
import { IMAGE } from "@/assets"
import { useTranslation } from "react-i18next"
import "./styles.less"

const IndexComponent = () => {
  const { t, i18n } = useTranslation()

  return (
    <div className="foot-component">
      <div className="logo-session">
        <img src={IMAGE.foot_logo} alt="" />
        <div className="buttons">
          {/* <img src={IMAGE.medium} alt="" onClick={() => window.open("https://futuresfoundation.medium.com/")} />
          <img src={IMAGE.twitter} alt="" onClick={() => window.open("https://twitter.com/FuturesCash")} /> */}
          <img src={IMAGE.email} alt="" onClick={() => window.open("mailto:contact@satogate.io")} />
        </div>
      </div>
      <h2 className="desc">{ t("foot.t2") }</h2>
      <div className="lang-session">
        <h2>{ t("foot.t1") }</h2>
        <div className="button" onClick={() => {
          if (i18n.language.startsWith("zh")) {
            i18n.changeLanguage("en")
          } else {
            i18n.changeLanguage("zh")
          }
        }}>
          <img src={IMAGE.lang} alt="" />
          <h1>{  t("language") }</h1>
        </div>
      </div>
    </div>
  )
}

export default IndexComponent